<template>
	<ViewLayout className="cam-front">
		<template v-slot:cam>
			<cam ref="cam" :ready="camready" :overlay="overlay" />
		</template>
		<template v-slot:body>
			<CamBreadcrumb :index="0" />
			<h1>{{ $store.strings.camera_frontTitle }}</h1>
			<h2 v-if="$store.strings.howto_notes && $store.strings.howto_notesTitle">{{ $store.strings.howto_notesTitle }}</h2>
			<ul v-if="$store.strings.howto_notes">
				<li v-for="note of $store.strings.howto_notes.split('\n')" :key="note">{{ note }}</li>
			</ul>
			<div class="spacer"></div>
		</template>
		<template v-slot:footer>
			<button class="uk-button uk-button-primary uk-button-large blue-action-button" :class="ready ? '' : 'uk-disabled'" onclick="_paq.push(['trackEvent', 'cam_front', 'triggered']);" @click="capture">
				<i uk-icon="icon: camera"></i>{{ $store.strings.camera_action }}
			</button>
			<router-link to="cistern" class="go-back-action-button" onclick="_paq.push(['trackEvent', 'cam_front', 'back']);">
				{{ $store.strings.general_back }}
			</router-link>
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue'
import CamBreadcrumb from '@/components/CamBreadcrumb.vue'
import Cam from '@/components/Cam.vue'
import Overlay from '@/assets/cam-overlay-front.svg'

export default {
	name: 'CamFront',
	components: {
		ViewLayout,
		Cam,
		CamBreadcrumb,
	},
	data: function() {
		return {
			ready: false,
			overlay: Overlay,
		}
	},
	methods: {
		capture() {
			const img = this.$refs.cam.onCapture()
			this.$store.request.images.front = img
			this.$router.push('cam-front-check')
		},
		camready() {
			this.ready = true
		}
	},
	created: function() {
		if (
			!this.$store.request ||
			this.$store.cistern === null
		) {
			this.$router.push('/')
		}
	},
}
</script>
